var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "main-section" },
    [
      _c("main-section-header", {
        attrs: {
          title: "설비 그룹 관리",
          subtitle:
            "설비그룹을 생성하여, 동종 설비들을 그룹화 하여 관리할 수 있습니다.<br />\n              설비그룹에 저장된 매뉴얼과 알림설정 내용은 그룹으로 지정된 설비에 동일하게 반영됩니다.",
        },
      }),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "list-utility-menu",
            {
              attrs: {
                hasSearchInput: true,
                placeholder: "설비 그룹명으로 검색하세요",
                value: _vm.keyword,
              },
              on: {
                input: _vm.onChangeKeyword,
                button: _vm.onMoveSearchedPage,
                keyupenter: _vm.onMoveSearchedPage,
              },
            },
            [
              _c(
                "button-basic",
                {
                  attrs: {
                    slot: "button",
                    color: "blue",
                    size: "s",
                    width: 113,
                    height: 32,
                  },
                  on: { click: _vm.onMoveEquipGroupResister },
                  slot: "button",
                },
                [_c("plus-icon"), _vm._v(" 등록 ")],
                1
              ),
            ],
            1
          ),
          _c(
            "list",
            {
              attrs: {
                list: _vm.equipGroupList,
                headerList: _vm.equipGroupHeaderList,
                "is-possible-hover-list": "isPossibleHoverList",
              },
              on: { sort: _vm.onClickSort },
            },
            [
              _vm._l(_vm.equipGroupList, function (equipGroup, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    attrs: { slot: "list-body" },
                    on: {
                      click: function ($event) {
                        return _vm.onMoveEquipGroupDetail(equipGroup["id"])
                      },
                    },
                    slot: "list-body",
                  },
                  [
                    _vm.isFactoryComp
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                equipGroup["groupType"] === "M"
                                  ? "도입 설비"
                                  : "자체 설비"
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(equipGroup["groupName"]))]),
                    _c("span", [
                      !equipGroup["manualFileUrl"]
                        ? _c("span", [_vm._v("-")])
                        : _c("span", { staticClass: "equip-file" }, [
                            _c(
                              "span",
                              { attrs: { "data-icon": "attach_file" } },
                              [
                                _c("vue-material-icon", {
                                  attrs: { name: "attach_file", size: 16 },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "button",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onClickFileOrLink({
                                      file: equipGroup["manualFileUrl"],
                                    })
                                  },
                                },
                              },
                              [_vm._v("파일 확인")]
                            ),
                          ]),
                    ]),
                    _c("span", [
                      !equipGroup["manualUrl"]
                        ? _c("span", [_vm._v("-")])
                        : _c("span", { staticClass: "equip-link" }, [
                            _c(
                              "span",
                              { attrs: { "data-icon": "link" } },
                              [
                                _c("vue-material-icon", {
                                  attrs: { name: "link", size: 16 },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "button",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onClickFileOrLink({
                                      link: equipGroup["manualUrl"],
                                    })
                                  },
                                },
                              },
                              [_vm._v("링크 확인")]
                            ),
                          ]),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(equipGroup["alertSettingsCount"] || "-")),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(equipGroup["equipmentCount"] || "-")),
                    ]),
                  ]
                )
              }),
              _c(
                "p",
                {
                  attrs: { slot: "no-list-description" },
                  slot: "no-list-description",
                },
                [_vm._v(_vm._s(_vm.noListDescription))]
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }