<template>
  <section class="main-section">
    <main-section-header
      title="설비 그룹 관리"
      subtitle="설비그룹을 생성하여, 동종 설비들을 그룹화 하여 관리할 수 있습니다.<br />
                설비그룹에 저장된 매뉴얼과 알림설정 내용은 그룹으로 지정된 설비에 동일하게 반영됩니다."
    />
    <div class="container">
      <list-utility-menu
        :hasSearchInput="true"
        placeholder="설비 그룹명으로 검색하세요"
        @input="onChangeKeyword"
        @button="onMoveSearchedPage"
        @keyupenter="onMoveSearchedPage"
        :value="keyword"
      >
        <button-basic slot="button" color="blue" size="s" :width="113" :height="32" @click="onMoveEquipGroupResister">
          <plus-icon />
          등록
        </button-basic>
      </list-utility-menu>
      <list
        :list="equipGroupList"
        :headerList="equipGroupHeaderList"
        is-possible-hover-list="isPossibleHoverList"
        @sort="onClickSort"
      >
        <li
          slot="list-body"
          v-for="(equipGroup, index) in equipGroupList"
          :key="index"
          @click="onMoveEquipGroupDetail(equipGroup['id'])"
        >
          <span v-if="isFactoryComp">
            {{ equipGroup['groupType'] === 'M' ? '도입 설비' : '자체 설비' }}
          </span>
          <span>{{ equipGroup['groupName'] }}</span>
          <span>
            <span v-if="!equipGroup['manualFileUrl']">-</span>
            <span v-else class="equip-file">
              <span data-icon="attach_file">
                <vue-material-icon name="attach_file" :size="16" />
              </span>
              <button @click.stop="onClickFileOrLink({ file: equipGroup['manualFileUrl'] })">파일 확인</button>
            </span>
          </span>
          <span>
            <span v-if="!equipGroup['manualUrl']">-</span>
            <span v-else class="equip-link">
              <span data-icon="link">
                <vue-material-icon name="link" :size="16" />
              </span>
              <button @click.stop="onClickFileOrLink({ link: equipGroup['manualUrl'] })">링크 확인</button>
            </span>
          </span>
          <span>{{ equipGroup['alertSettingsCount'] || '-' }}</span>
          <span>{{ equipGroup['equipmentCount'] || '-' }}</span>
        </li>
        <p slot="no-list-description">{{ noListDescription }}</p>
      </list>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import ListUtilityMenu from '@/component/list/ListUtilityMenu';
import List from '@/component/list/List';
import ListHeaderData from './ListHeaderData.json';
import noListDescription from '@/asset/js/noListDescription';

export default {
  name: 'EquipmentGroupList',
  props: [],
  data() {
    return {
      equipGroupList: null,
      equipGroupHeaderList: ListHeaderData,
      keyword: '',
      sortOrder: '',
      isRequestedDataWithKeyword: false,
    };
  },
  computed: {
    ...mapState('users', ['currentCompany']),
    ...mapGetters('users', ['isFactoryComp']),
    noListDescription() {
      return noListDescription(this.isRequestedDataWithKeyword);
    },
  },
  created() {
    this.getEquipGroups();
  },
  mounted() {},
  watch: {
    $route() {
      this.getEquipGroups();
    },
  },
  methods: {
    ...mapActions('manufacturerEqpGroups', ['GET_MANUFACTURER_EQP_GROUPS']),
    ...mapActions('factoryEqpGroups', ['GET_FACTORY_EQP_GROUPS']),
    onClickFileOrLink({ file, link }) {
      if (file) {
        window.open(file);
      } else if (link) {
        link.startsWith('http') ? window.open(link) : window.open(`http://${link}`);
      }
    },
    setQueryParams() {
      this.keyword = this.$route.query.keyword || '';
      this.sortOrder = this.$route.query.sortOrder || '';
    },
    getEquipGroups() {
      this.setQueryParams();
      this.isRequestedDataWithKeyword = !!this.keyword;
      this.equipGroupList = null;
      switch (this.currentCompany) {
        case 'M':
          this.getManufacturerEqpGroups();
          break;
        case 'F':
          this.getFactoryEqpGroups();
          break;
      }
    },
    async getManufacturerEqpGroups() {
      try {
        this.equipGroupList = await this.GET_MANUFACTURER_EQP_GROUPS([this.sortOrder, this.keyword]);
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    async getFactoryEqpGroups() {
      try {
        this.equipGroupList = await this.GET_FACTORY_EQP_GROUPS([this.sortOrder, this.keyword]);
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    onMoveEquipGroupResister() {
      this.$router.push(`equipment-groups/register`).catch((err) => err);
    },
    onMoveEquipGroupDetail(gid) {
      this.$router.push(`equipment-groups/${gid}`).catch((err) => err);
    },
    onMoveSearchedPage() {
      this.$router
        .push({
          query: {
            sortOrder: this.sortOrder || undefined,
            keyword: this.keyword || undefined,
          },
        })
        .catch((err) => err);
    },
    onChangeKeyword(changeKeyword) {
      this.keyword = changeKeyword;
    },
    onClickSort(sort) {
      if (this.sortOrder) {
        this.sortOrder = `${this.sortOrder.indexOf('-') === 0 ? '' : '-'}${sort}`;
      } else {
        this.sortOrder = sort;
      }
      this.onMoveSearchedPage();
    },
  },
  components: { MainSectionHeader, ListUtilityMenu, List },
};
</script>

<style scoped lang="scss">
@import 'EquipmentGroupList';
</style>
